import React, { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Navbar from "../components/layout/Navbar";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Spline from "@splinetool/react-spline";
import ReactMarkdown from "react-markdown";
import markdownContent from "../markdown/nebula-value-proposition.md";

const MarkdownComponents = {
	h1: ({ node, ...props }) => (
		<Typography
			variant="h4"
			color="white"
			sx={{
				fontFamily: "Electrolize, Arial, sans-serif",
				fontWeight: 600,
				marginBottom: 2,
			}}
			{...props}
		/>
	),
	h2: ({ node, ...props }) => (
		<Typography
			variant="h5"
			color="white"
			sx={{
				fontFamily: "Electrolize, Arial, sans-serif",
				fontWeight: 600,
				marginBottom: 2,
			}}
			{...props}
		/>
	),
	h3: ({ node, ...props }) => (
		<Typography
			variant="h6"
			color="white"
			sx={{
				fontFamily: "Electrolize, Arial, sans-serif",
				fontWeight: 600,
				marginTop: 4,
				marginBottom: 2,
			}}
			{...props}
		/>
	),
	p: ({ node, ...props }) => (
		<Typography
			variant="body2"
			sx={{
				fontFamily: "Electrolize, Arial, sans-serif",
				color: "#FFFFFF",
				marginBottom: 2,
			}}
			{...props}
		/>
	),
	strong: ({ node, ...props }) => (
		<strong style={{ fontWeight: 700 }} {...props} />
	),
};

const NebulaValueProposition = () => {
	const navigate = useNavigate(); // Initialize useNavigate

	const handleNavigationHome = () => {
		navigate("/"); // Navigate to the "/" route
	};

	return (
		<Box
			sx={{
				position: "relative",
				width: "100%",
				height: "100dvh", // Full height of viewport
				overflow: "auto",
				backgroundColor: "black", // Set background color to black for the whole page
			}}
		>
			{/* Navbar */}
			<Navbar
				backgroundColor="black"
				sx={{
					position: "sticky",
					top: 0,
					left: 0,
					width: "100%",
					boxShadow: "none",
					zIndex: 2, // Ensure the navbar stays above the particles
				}}
			/>

			<Stack>
				{/* Top Section with Spline Animation */}
				<Box
					sx={{
						height: "40vh",
						position: "relative",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						alignItems: "center",
						borderBottom: "1px solid",
						borderColor: "#272727",
						backgroundColor: "transparent", // Transparent so particles show
						zIndex: 1,
						"@media print": {
							borderBottom: "none", // Remove borders for print
							backgroundColor: "transparent !important", // Ensure no background color for printing
							height: "auto",
						},
					}}
				>
					{/* Spline Background */}
					<Box
						sx={{
							position: "absolute",
							width: "100%",
							height: "100%",
							top: 0,
							left: 0,
							zIndex: -1, // Particles stay behind the content
							"@media print": {
								display: "none !important", // Hide particles background when printing
								height: "auto",
							},
						}}
					>
						<Spline
							scene="https://prod.spline.design/AR2OsVSryukNaSwZ/scene.splinecode"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
						/>
					</Box>

					<Stack
						spacing={1}
						sx={{
							width: "100%",
							maxWidth: "776px",
							alignItems: "start",
							paddingLeft: "24px",
							paddingRight: "24px",
							paddingBottom: { xs: "24px", sm: "24px", md: "24px", lg: "24px" },
							zIndex: 2, // Ensure text is above the particles
							"@media print": {
								height: "auto",
							},
						}}
					>
						<Button
							onClick={handleNavigationHome}
							variant="text"
							startIcon={
								<ArrowBack
									sx={{
										fontSize: "16px",
										"&:hover": {
											color: "#FFF1D5", // Text color when hovered
											textShadow:
												"0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
										},
									}}
								/>
							}
							sx={{
								fontFamily: "Electrolize, Arial, sans-serif",
								color: "#DCDCDC",
								fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
								fontWeight: "500",
								"@media (hover: hover)": {
									"&:hover": {
										color: "#FFF1D5", // Text color when hovered
										textShadow:
											"0 0 1px #FFF1D5, 0 0 2px #FFF1D5, 0 0 8px #FFF1D5", // Glowing effect
									},
								},
							}}
						>
							Home
						</Button>
						<Typography
							gutterBottom
							color="white"
							sx={{
								typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4" },
							}}
						>
							Nebula Value Proposition
						</Typography>
						<Typography
							sx={{
								fontFamily: "Electrolize, Arial, sans-serif",
								color: "#DCDCDC",
								fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "20px" },
								fontWeight: "500",
								"@media print": {
									fontSize: "14px !important",
								},
							}}
						>
							8 min read
						</Typography>
					</Stack>
				</Box>

				{/* Main Content Section: Render markdown content */}
				<Box
					sx={{
						maxWidth: "776px",
						margin: "0 auto",
						padding: 3,
						paddingY: { xs: "24px", sm: "24px", md: "36px", lg: "36px" },
						backgroundColor: "black",
						zIndex: 1,
					}}
				>
					<ReactMarkdown components={MarkdownComponents}>
						{markdownContent}
					</ReactMarkdown>
				</Box>
			</Stack>
		</Box>
	);
};

export default NebulaValueProposition;
