// src/routes.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import FHE from "./pages/FHE";
import DEX from "./pages/DEX";
import PLEAK from "./pages/pLEAK";
import LEAK from "./pages/LEAK";
import PrivateDataDex from "./pages/private-data-dex";
import CompetitivePerformance from "./pages/competitive-performance";
import OFS from "./pages/OFS";
import OFS2 from "./pages/OFS2";
import TeamBio from "./pages/team-bio";
import NebulaValueProposition from "./pages/nebula-value-proposition";

const AppRoutes = () => (
	<Routes>
		<Route path="/" element={<HomePage />} />
		<Route path="/FHE" element={<FHE />} />
		<Route path="/DEX" element={<DEX />} />
		<Route
			path="/p-strategy-leakage-and-competitive-performance"
			element={<PLEAK />}
		/>
		<Route
			path="/strategy-leakage-and-competitive-performance"
			element={<LEAK />}
		/>
		<Route path="/private-data-dex" element={<PrivateDataDex />} />
		<Route
			path="/competitive-performance"
			element={<CompetitivePerformance />}
		/>
		<Route path="/concise-technical-document-for-kyle" element={<OFS />} />
		<Route path="/concise-technical-document" element={<OFS2 />} />
		<Route path="/select-team-members" element={<TeamBio />} />
		<Route
			path="/nebula-value-proposition"
			element={<NebulaValueProposition />}
		/>
	</Routes>
);

export default AppRoutes;
